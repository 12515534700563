.cam-top {
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 90%;
}

.each-cam {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 30px;
    margin-bottom: 30px;
    background: #484848;
    width: 300px;
    border-radius: 10px;

}

.each-cam-bottom {
    background: #484848;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.each-cam-bottom-inner {
    width: 90%;
}

.last-camp {
    width: 80px;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #57b100;
    border-radius: 10px;
    color:white;
    cursor: pointer;
}

.top-titles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}


.main-edit-camp {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.main-edit-camp-left {
    width: 50%;
    min-width:350px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #484848;
    border-radius: 10px;
    height: 500px;
}

.main-edit-camp-left-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #484848;
    border-radius:5px;
    width: 100%;
    color:white

}

.edit-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 40px;
    padding-top: 20px;
    
}

.edit-two {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 30px;
    padding-bottom: 20px;
}

.edit-two-inner {
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.main-edit-camp-right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color:white
}

.boths {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.FlowPlayer{
    width: "100%";
    height: "400px";
}

.tests {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 30px;
    border-left: 1px solid gray;
    width: 40%;
    overflow-y: auto;
    height: 600px;
    padding-top: 25px;
}

.each-test {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
    min-height: 80px;
}

@media (max-width:800px) {
    .cam-top {
        margin-top: 20px;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        width: 90%;
    }
}   