.settings-main-low {
    width: 60%;
    background-color: #484848;
    height: 600px;
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    align-self: flex-start;

    margin-left: 5%;
}

.profile-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 40px;
}

.profile-right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 40px;
}