.cam-top {
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 90%;
}

.each-cam {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 30px;
    margin-bottom: 30px;
    background: #484848;
    width: 300px;
    border-radius: 10px;

}

.each-cam-det {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #484848;
    width: 500px;
    border-radius: 0px 0px 10px 10px;
}

.each-cam-bottom {
    background: #484848;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.each-cam-slim-bottom {
    background: #484848;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    color:white
}

.each-cam-slim-bottom-det {
    background: #484848;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    
    color:white
}

.inners {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inners-btn {
    width: 200px;
    height: 40px;
    background-color: rgba(87, 177, 0, 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 40px;
}

.each-cam-bottom-inner {
    width: 90%;
}

.last-camp {
    width: 80px;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #57b100;
    border-radius: 10px;
    color:white
}



@media (max-width:800px) {
    .cam-top {
        margin-top: 20px;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        width: 90%;
    }
}  