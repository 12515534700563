.bottom-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 150px;
    
}

.main-bulk {
    height: 400px;
    min-height: 400px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #3b3c3a;
    overflow-x: hidden;
    
}

.bulk-modal {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.bulk-modal-p1 {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    width: 100%;
    color:white
}

.bottom-each {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #57b100;
    border-radius: 10px;
    width: 280px;
    height: 45px;
    cursor: pointer;
}

