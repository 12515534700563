.textField{
    left: 13.61%;
    right: 67.25%;
    top: 33.98%;
    bottom: 62.11%;
    height: 40px;
    width: 239px;
    background: #FFFFFF;
    border: 1px solid #E4DFDF;
    box-sizing: border-box;
    border-radius: 5px;
    margin: auto;
} 
.text{
    font-size: 17px;
    margin: auto;
} 
.signup{
    margin: auto;
    width:inherit
} 

