.background{
    background-color:#393a39 ;
    
} 
.text{
   
font-size: 20px;
line-height: 30px;
letter-spacing: 0px;
text-align: left; 
color: white;
width: 75%;
font-family: 'Anton', sans-serif;
font-family: 'Work Sans', sans-serif;
} 
.form{
    background-color: #484848;
    width:61%;
    height: 635px;
    flex-direction: column;
    border-radius: 20px;
} 
  
  
.company{
    height: 40px;
    width: 92%;
    margin: auto;
    text-align: center;
} 

.passreset{
    height: 40px;
    width: 92%;
    margin: auto;
    text-align: center;
}