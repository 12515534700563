.new-cam-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    width: 90%;
   
}

.cam-dropzone {
    width: 60%;
    min-width: 300px;
    height: 500px;
}

.cam-right {
    width: 35%;
    min-width: 300px;
    margin-left: 20px;
    color:white
}

.cam-right-each {

}

.btn-cam-add {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #57b100;
    height: 40px;
    width: 99%;
    color:"white";
    border-radius: 5px;
    cursor: pointer;
    
}

.input-sign-area {
    width: 96%;
    min-height: 140px;
    border-radius: 5px;
    box-shadow: none;
    margin-bottom: 20px;
    padding-left: 10px;
    border:none;
}