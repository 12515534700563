.textField{
    left: 13.61%;
    right: 67.25%;
    top: 33.98%;
    bottom: 62.11%;
    height: 40px;
    width: 239px;
    background: #FFFFFF;
    border: 1px solid #E4DFDF;
    box-sizing: border-box;
    border-radius: 5px;
} 
.signup{
    
    
    
    font-family: Work Sans;
    font-size: 22px;
    
    /* identical to box height */
    
  height:26px;
    
    color: #FFFFFF;
} 
.signupbtn{
    
   
    
    height: 25px;
    width: 79%;
    margin: auto;
    
    background:none;
    border: none; 
    color: white;
    
    
   

}  
