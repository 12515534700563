.modal-main-test {
    height:600px;
    width:500px;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #484848;
    align-items: center;
}

.modal-main-test-top {
    width:90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal-main-test-top p {
    font-size: 20px;
    color:white;
    cursor: pointer;
}

.scrolled-contacts {
    width: 90%;
    overflow-y: auto;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}

.each-con {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right:10px;
}

.each-con-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.each-con-left img{
    height: 30px;
    width: 30px;
}

.each-con-left p {
    color:white
}

.bottom-cont {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    justify-content: space-around;
}

.success-contact-modal {
    height: 140px;
    width: 300px;
    background-color: #484848;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left:20px;
    padding-right:20px;

    align-items: center;
}

.warning-contact-modal {
    height: 540px;
    width: 320px;
    background-color: #484848;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left:20px;
    padding-right:20px;

    align-items: center;
}

.warning-contact-modal p {
    font-size: 20px;
    color:white
}

.success-contact-modal p {
    font-size: 22px;
    color:white
}

.success-contact-btns {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

