.main-root{
    min-height: 100vh;
    display: flex;
    background: #3b3c3a;
}

.Online {
    position:absolute;
    left:2%;
    top:100px;
    z-index: 10;
    color:#57b100
}
.Offline {
    position:absolute;
    left:2%;
    top:100px;
    z-index:10;
    color:red
}