.contact-img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 2px solid white;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}