.pagination {
    display: flex;
    max-width: 500px;
    width:30%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    align-self: flex-end;
}

.page-item {
    background-color: #57b100;
    height: 30px;
    width: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    cursor: pointer;
}

.page-item p {
    color:white
}