.coup-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 13%;
    min-width: 170px;
    height: 40px;
    border-radius: 10px;
    background-color: #57b100;
    cursor: pointer;
}

.coup-btn p {
    color:white
}

.coupon-table {
    width: 90%;
    align-self: center;
    border-radius: 20px;
    border-spacing: 0px;
}

.coupon-table tr {
    background-color: #202020;
    height: 60px;
    
}


.coupon-table tr th {
    color:white;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

.coupon-table tr td {
    color:white;
    
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.de-actived {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    border:1px solid #FC5C29;
    border-radius: 5px;
    cursor: pointer;
}

.de-actived p {
    font-size: 14px;
    color:#FC5C29
}

.actived {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    border:1px solid #57B100;
    border-radius: 5px;
    cursor: pointer;
}

.actived p {
    font-size: 14px;
    color:#57B100
}

.shared-btn {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    border:1px solid white;
    border-radius: 5px;
    cursor: pointer;
}

.shared-btn p {
    font-size: 14px;
    color:white
}

.share-coupon-modal {
    height: 500px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #3B3C3A;
    align-items: center;
    
}

.share-abs {
    position: absolute;
    top:10px;
    right:10px; 
}

.share-holder {
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    margin-top: 30px;
}

.share-modal-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.share-modal-top-right {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.share-modal-middle {
    width: 95%;
    background-color: #484848;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    align-self: center;
    padding-left: 20px;
}

.share-modal-middle-input {
    background-color: #484848;
    border:none;
    margin-left: 20px;
    color:white;
    font-size: 18px;

}

.share-modal-middle-input:focus {
    outline:none
}

.send-btn {
    width: 60%;
    height: 40px;
    border-radius: 5px;
    background: #57b100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    cursor: pointer;
}