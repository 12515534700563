.main-landing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #393a39;

    width: 100%;
    overflow-y: hidden;
    scroll-behavior: smooth;
    /* object-fit: fill; */

}
.main-landing-only {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #393a39;

    width: 100%;
    overflow-y: hidden;
    height: 100vh;
    scroll-behavior: smooth;
    /* object-fit: fill; */

}


.abs-rect {
    position: fixed;
    top:-2px;
    right:-10px;
    height: 100%;

}

.landing-middle-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 80%;
}



.landing-logo {
    height: 110px;
    width: 500px;
}

.sign-logo {
    height: 100px;
    width: 400px;
}

@media (max-height: 730px) {

    .sign-logo {
        height: 30px;
        width: 140px;
    }

}

@media (max-width:1450px) {
    .sign-logo {
        height: 40px !important;
        width: 200px !important;
        margin-top: 40px !important;
    }
}

.sign-middle-login {
    width: 350px;
    height: 510px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #484848;
    margin-top: 20px;
    border-radius: 20px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (max-width:1450px) {
    .sign-middle {
        height: 480px !important;
    }
}

.landing-txt {
    width: 50%;
    color:white;
    margin-top: 5%;
}

.landing-middle-right {
    z-index: 2;

}

.sign-middle-left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.input-sign {
    width: 93%;
    height: 40px;
    border-radius: 5px;
    box-shadow: none;
    margin-bottom: 20px;
    padding-left: 10px;
    border:none;
}

@media (max-width:1450px) {
    .input-sign {
        width: 93%;
        height: 30px !important;
        border-radius: 5px;
        box-shadow: none;
        margin-bottom: 20px;
        padding-left: 10px;
        border:none;
    }
    .login-btn {
        width: 100%;

        background: #57b100;
        height: 30px !important;
        width: '100%';
        color:white;
        border-radius: 5px;
        border:none;
        outline: none;
    }
}

.sign-btn {
    background: none;
    color:white;
    border:none;
    font-family: 'Work Sans';
    font-size: 18px;
}

.login-btn {
    width: 100%;

    background: #57b100;
    height: 40px;
    width: '100%';
    color:white;
    border-radius: 5px;
    border:none;
    outline: none;
    margin-bottom: 10px;
}

.white-btn {
    background: white;
    border-radius:5px;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}

@media (max-width:1450px) {
    .white-btn {
        background: white;
        border-radius:5px;
        display: flex;
        flex-direction: row;
        height: 30px !important;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
    }
}


.kep-login-facebook {
    width:290px;
    height:30px;
    /* visibility: hidden; */
    opacity: 0;
    position:relative;
    top:100%;
    z-index:10;
    cursor: pointer;


}

/* For Mobile Phones*/


@media (min-width:1200px) {
    .main-landing {
        /* padding-top: 100px; */
        overflow-y: hidden;
    }
    .landing-middle-right {
        width:500px
    }
}

@media (min-width:900px) {
    .main-landing {
        /* padding-top: 100px; */
        overflow-y: hidden;
    }
    .landing-middle-right {
        width:450px !important
    }
}



@media (max-width:800px){

    .main-landing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #393a39;
        height: 100vh;
        overflow-y: hidden;
        width: 100%;
        object-fit: fill;
    }


    .landing-middle-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        min-width: 300px;
        z-index: 2;
    }

    .landing-middle-right {
        z-index: 2;
        width: 250px;
        height: 250px;
        display: none;

    }

    .landing-logo {
        height: 80px;
        width: 300px;
    }

    .landing-txt {
        width: 80%;
        color:white;
        margin-top: 5%;
    }

    .abs-rect {
        position: fixed;
        top:-2px;
        right:-50px;
        height: 100%;
    }
}


/* Laptop resolutions   1280 x 720   */

/* @media (min-height: 720px) and (max-height: 768px)
{

    .main-landing {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        height: 650px;
        width: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        background-color: gray;

    }

    .abs-rect {
        position: fixed;
        top:-2px;
        right:-10px;
        height: 100%;

    }

    .landing-middle-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;
        height: 650px;
    }

    .sign-middle {
        width: 350px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: #484848;
        margin-top: 50px;
        border-radius: 20px;
        align-items: center;
    }


    .sign-logo {
        height: 80px;
        width: 200px;
    }



    .landing-txt {
        width: 50%;
        color:white;
        margin-top: 5%;
    }

    .landing-middle-right {
        z-index: 2;

    }

    .sign-middle-left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 80%;
        margin-top: -20px;
    }

    .input-sign {
        width: 93%;
        height: 25px;
        border-radius: 5px;
        box-shadow: none;
        margin-bottom: 20px;
        padding-left: 10px;
        border:none;
    }

    .sign-btn {
        background: none;
        color:white;
        border:none;
        font-family: 'Work Sans';
        font-size: 18px;
    }

    .login-btn {
        width: 100%;

        background: #57b100;
        height: 40px;
        width: '100%';
        color:white;
        border-radius: 5px;
        border:none;
        outline: none;
    }

    .white-btn {
        background: white;
        border-radius:5px;
        display: flex;
        flex-direction: row;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }


    .kep-login-facebook {
        width:290px;
        height:30px;

        opacity: 0;
        position:absolute;
        top:74%;
        z-index:10;
        cursor: pointer;


    }
} */