.main-landing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #393a39;
    min-height: 100vh;
    height: auto;
    width: 100%;


}
.main-landing-signup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #393a39;
    height: 100vh;
    
    width: 100%;
}

.abs-rect {
    position: fixed;
    top:-2px;
    right:-10px;
    height: 100%;
}

.landing-middle-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}



.landing-logo {
    height: 110px;
    width: 500px;
}

.sign-logo {
    height: 100px;
    width: 400px;
}

.sign-middle {
    width: 350px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #484848;
    margin-top: 20px;
    border-radius: 20px;
    align-items: center;
}
.sign-middle-new {
    width: 360px;
    height: 540px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #484848;
    margin-top: 20px;
    border-radius: 20px;
    align-items: center;
}

.landing-txt {
    width: 50%;
    color:white;
    margin-top: 5%;
}

.landing-middle-right {
    z-index: 2;
    
}

.sign-middle-left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
}

.input-sign {
    width: 93%;
    height: 40px;
    border-radius: 5px;
    box-shadow: none;
    margin-bottom: 20px;
    padding-left: 10px;
}

.sign-btn {
    background: none;
    color:white;
    border:none;
    font-family: 'Work Sans';
    font-size: 18px;
    border:none;
}

@media (max-width:800px) {

    .main-landing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        background-color: #393a39;
        height: 100vh;
        overflow-y: hidden;
        width: 100%;
    
    }
    

    .landing-middle-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        min-width: 300px;
        z-index: 2;
    }

    .landing-middle-right {
        z-index: 2;
        width: 300px;
        height: 300px;
        display: none;
        
    }

    .landing-logo {
        height: 80px;
        width: 300px;
    }

    .landing-txt {
        width: 80%;
        color:white;
        margin-top: 5%;
    }

    .abs-rect {
        position: fixed;
        top:-2px;
        right:-50px;
        height: 100%;
    }
}