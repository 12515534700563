.submit1{
    
border-radius: 5px;
background-color: rgba(87, 177, 0, 1);
    width: 31%;
    margin: auto;
    height: 35px;
    color: white;
}
.cancel{
    border: none;
   color: rgba(87, 177, 0, 1); 
   background: none;
}