.header-main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background:#3b3c3a;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #666;
}

.header-input-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: #484848;
    border-radius: 5px;
    width: 30%;
    height: 40px;

}

.header-inp {
    width: 88%;
    background: none;
    border:none;
    outline: none;
    height: 75%;
    color:white
}

.create-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 200px;
    align-items: center;
    align-items: center;
}

.create-btn {
    background: #57b100;
    height: 30px;
    width: 35px;
    border:none;
    border-radius: 5px;
    color:white
   
}

.create-div p {
    font-family: 'Work Sans';
    color:white
}

.header-last {
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}