.side-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #202020;
    width: 80px;
    min-height: 100%;
    z-index: 1;
    top:0
}

@keyframes openbar {
    0% {
        width: 80px;
    }
    20% {
        width: 100px;
    }
    40% {
        width:120px;
    }
    60% {
        width: 150px;
    }
    80% {
        width: 175px;
    }
    100% {
        width: 200px;
    }
    
}

@keyframes openreverse {
    0% {
        width: 200px;
    }
    50% {
        width: 140px;
    }
    100% {
        width: 80px;
    }
}

.side-menu-full {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #202020;
    width: 200px;
    min-height: 100%;
    z-index: 1;
    animation: openbar 0.2s linear forwards;
    
}


.side-menu {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: openreverse 0.2s linear forwards
}


.side-middle {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 600px;
    align-items: center;
}

.side-middle-full {
    margin-top: 30px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 600px;
    align-items: flex-start;
}

.side-middle-full p {
    color:white
}