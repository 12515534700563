.staff-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 220px;
    background-color: #57B100;
    color:white;
    border-radius: 10px;
}

.all-staff {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #484848;
    
    align-self: flex-start;
    margin-left: 5%;
    border-radius: 20px;

}

.all-staff-each {
    width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.all-staff-each-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: pointer;
    color:white
}

.all-staff-each-top-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    min-width: 150px;
}

.all-staff-each-top-left-more {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.staff-div-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 5%;
    padding-right: 5%;
    align-items: center;
    height: 40px;
    width: 195px;
    background-color: #3b3c3a;
    border: 2px solid #57B100;
    color:white;
    border-radius: 10px;
}