.textField{
    left: 13.61%;
    right: 67.25%;
    top: 33.98%;
    bottom: 62.11%;
    height: 40px;
    width: 239px;
    background: #FFFFFF;
    border: 1px solid #E4DFDF;
    box-sizing: border-box;
    border-radius: 5px;
}  
.checkbox{
    font-family: Work Sans;
    color: #FFFFFF;
    font-size: 15px;
} 
.forgetbtn{
    background:none;
    border: none; 
    color: rgba(87, 177, 0, 1);
    height: 64px;
font-size: 14px;
} 
.loginbtn{
    background: #57B100;
/* border-radius: 5px  ; */
border: 1px solid #E4DFDF;
font-size: 14px;
height: 32px;
width: 270px;
color: white;
}
.H4{
    color: white;
    text-align: center;
}
.loginfb{
    background:white;
    height: 37px;
}
.logingoo{
    background:white;
    height: 37px;
}