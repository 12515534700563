.custom-dialog {
    width:400px;
    background: #3b3c3a;
    overflow-x: hidden;
    min-height: 200px;
}

.cd-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding:10px;
    width: 100%;
}

.cd-heading {
    color:white;
    font-weight: 600;
    font-size: 20px;
    margin-top: 10px;
    width:90%
}

.cd-text {
    margin-top: 10px;
   
    color:white;
    width:90%
}

.button-set {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
}

.pop-btn {
    min-width: 30%;
    margin-top: 10px;
    background: #57b100;
    height: 40px;
    width: '100%';
    color:white;
    border-radius: 5px;
    border:none;
    outline: none;
    cursor: pointer;
}