.settings-main {
    width: 90%;
    background-color: #484848;
    height: 600px;
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    overflow-y: auto;
}



.settings-main-short {
    width: 30%;
    background-color: #484848;
    height: 600px;
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-self: flex-start;
    margin-left: 5%;
    min-width: 300px;
}

.settings-innermain {
    width: 93%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    color:white;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #777;
    cursor: pointer;

}

.logouts-setting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 130px;
    color:white;
    background-color: #57b100;
    border-radius: 5px;
    margin-top: 50px;
    align-self: flex-start;
    margin-left: 3.5%;
}
.input-sign-new {
    width: 60%;
    height:40px !important;
    border-radius: 5px;
    box-shadow: none;
    margin-bottom: 10px;
    padding-left: 10px;
    border:none
}

.login-btn-small {
    width: 63%;
    margin-top: 10px;
    background: #57b100;
    height: 40px;
    width: '100%';
    color:white;
    border-radius: 5px;
    border:none;
    outline: none;
}

.main-left-not {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 45%;
    color:white

}
.main-left-not-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.not-main {
    width: 100%;
    background-color: #484848;
    height: 600px;
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    min-width: 400px;
    overflow-y: scroll;
}